import React, { RefObject } from "react"
import "./VisioMapView.css"

declare global {
  interface Window {
    visioweb: any
  }
}

class VisioMapView extends React.Component<
  VisiomapProps,
  { mapInit: boolean; itemClicked: VisioPlace | null }
> {
  mapviewer = new window.visioweb.Mapviewer()

  mapParam: VisioMapParameterType
  multiBuildingParameter?: MultiBuildingParameterType | null
  onlyOnce = false
  myRef: RefObject<HTMLDivElement>
  selectedPlaceId: string | null = null
  myRoute: any
  fctAfterInit: null | ((value: boolean) => void) = null
  fctAfterMultiInit: null | ((value: boolean) => void) = null

  /**
   *
   * -----------------------------------------------------------Exported function --------------------------------------------------------------------------------
   *
   */

  /**
   * Retourne sur la vue global
   */
  goToDefault = (): void => {
    this.mapviewer.multiBuildingView.goTo({
      mode: "global",
      buildingID: this.mapviewer.multiBuildingView.DEFAULT,
      floorID: this.mapviewer.multiBuildingView.DEFAULT,
      animationDuration: 1,
    })
  }

  /**
   * Retourne sur la vue d'un building
   * @param {string} id
   */
  goToBuilding = (id: string): void => {
    this.mapviewer.multiBuildingView.goTo({
      mode: "building",
      buildingID: id,
      animationDuration: 1,
    })
  }

  /**
   * Retourne sur la vue d'un étage
   * @param {string} id
   */
  goToFloor = (id: string): void => {
    this.mapviewer.multiBuildingView.goTo({
      mode: "floor",
      floorID: id,
      animationDuration: 1,
    })
  }

  /**
   * Retourne sur la vue d'une place
   * @param {string} id
   */
  goToPlace = (id: string): void => {
    const placeTmp = this.mapviewer.getPlace(id)
    this.mapviewer.multiBuildingView.goTo({
      mode: "floor",
      floorID: placeTmp.vg.floor,
      animationDuration: 1,
      place: placeTmp.vg.id,
    })
  }

  /**
   * Lancer la navigation pour faire un itineraire
   * @param {string} idSrc
   * @param {string} idDst
   * @param {string} customImagepath
   */
  navigation = (
    idSrc: string,
    idDst: string,
    customImagepath?: string
  ): void => {
    const srcPlace = this.mapviewer.getPlace(idSrc)
    const dstPlace = this.mapviewer.getPlace(idDst)

    if (srcPlace.vg && dstPlace.vg) {
      const pRouteRequest = {
        src: srcPlace.vg.position,
        dst: dstPlace.vg.position,
        computeNavigation: true,
        navigationParameters: {
          algorithm: "auto",
        },
      }

      this.mapviewer
        .computeRoute(pRouteRequest) // On créer l'itinéraire
        .then((request: any, data: any) => {
          this.mapviewer.addPath(request.data)
          const options = {
            imagePath:
              customImagepath !== undefined ? customImagepath : "./media",
            useLinks: true,
          }
          const route = new window.visioweb.Route(
            this.mapviewer,
            request.data,
            options
          ) // Route set
          route.isValid() // Verification si la route est valide Attention Si la route n'est pas set dans le map creator renvoie true quand même
          route.show()
          this.myRoute = route
        })
        .catch((e: any) => {
          console.error("Erreur : ", e)
        })
    } else {
      console.error("Erreur with Start point or End point")
    }
  }

  /**
   * Permet d'aller à une certaine étapes
   * @param {number} stepNumber
   */
  routeGotoStep = (stepNumber: number): void => {
    if (this.myRoute !== null) {
      const navigation = this.myRoute.navigation
      for (
        let i = navigation.getCurrentInstructionIndex();
        i < stepNumber;
        i++
      ) {
        navigation.displayNextInstruction()
      }
    }
  }

  /**
   * Stop la Navigation
   */
  routeStop = (): void => {
    if (this.myRoute !== null) {
      this.myRoute.hide()
      this.myRoute.remove()
      this.myRoute = null
    }
  }

  /**
   * Recupère les instruction de navigation
   * @returns {any}
   */
  getNavigationInstruction = (index: number): any => {
    //TODO VisioWeb doc = Object
    return this.myRoute.navigation.getInstructionData(index)
  }

  /**
   * Affiche la prochaine instruction
   */
  displayNextInstruction = (): void => {
    this.myRoute.navigation.displayNextInstruction()
  }

  /**
   * Affiche l'instruction precedente
   */
  displayPrevInstruction = (): void => {
    this.myRoute.navigation.displayPrevInstruction()
  }

  /**
   * Permet de setter la langue des place
   * @param places (map of place names)
   * Places:
   *  {
   *      placeID001: {
   *          name:'place1',
   *          description: 'Test place1'
   *      },
   *      placeID002: {
   *          name:'place2',
   *          description: 'Test place2'
   *      }
   *  }
   *
   */
  setupNavigationTranslator = (places: {
    [id: string]: VisioPlaceTranslator
  }): void => {
    this.mapviewer.setupNavigationTranslator(places)
  }

  /**
   * Permet de traduire les instructions
   * @param lang
   */
  translateInstruction = (lang: string): void => {
    this.mapviewer.navigationTranslator.translateInstructions(
      this.myRoute.navigation,
      lang
    )
  }

  /**
   * Ajoute une popup
   * @param {POIData} data
   */
  addPoiOnMap = (data: POIData): void => {
    this.mapviewer.addPOI(data)
  }

  /**
   * Recupere les informations d'un place
   * @param {string} id
   * @returns {Promise<VisioPlace>}
   */
  getPlaceInfo = (id: string): VisioPlace => {
    return this.mapviewer.getPlace(id)
  }

  /**
   * Recupere la liste de toutes les places
   * @returns {any}
   */
  getPlaces = (): VisioPlace[] => {
    return this.mapviewer.getPlaces()
  }

  /**
   * Retourne le nom de la place/floor/building
   * @param id
   */
  getName = (id: string): string => {
    return this.mapviewer.multiBuildingView.getLocalizedName(id)
  }

  /**
   * Retourne le nom de la place (ne fonctionne pas toujours)
   * @param id
   */
  getPlaceName = (id: string): string | boolean => {
    return this.mapviewer.getPlaceName(id)
  }

  /**
   * Recupere la liste des id des floors
   * @returns {any}
   */
  getFloors = (): string[] => {
    return this.mapviewer.getFloors().map((f: any) => f.name)
  }

  /**
   * Recupere la liste des id des batiments
   * @returns {any}
   */
  getBuildings = (): any => {
    if (this.mapviewer.multiBuildingView) {
      return this.mapviewer.multiBuildingView.getVenueLayout().buildings
    } else {
      return null
    }
  }

  /**
   * Recupere la liste des batiments par id
   * @returns {any}
   */
  getBuildingsId = (): any => {
    if (this.mapviewer.multiBuildingView) {
      return this.mapviewer.multiBuildingView.getVenueLayout().buildingByID
    } else {
      return null
    }
  }

  /**
   * Retourne la position selectionne
   * @returns {any}
   */
  getClickedPosition = (): any => {
    if (this.mapviewer.multiBuildingView) {
      return this.mapviewer.multiBuildingView.getCurrentExploreState()
    } else {
      return null
    }
  }

  /**
   * Retourne l'etage de la position selectionne
   * @returns {any}
   */
  getClickedFloor = (): any => {
    if (this.mapviewer.multiBuildingView) {
      return this.mapviewer.multiBuildingView.getCurrentExploreState().floorID
    } else {
      return this.mapviewer.getCurrentFloor()
    }
  }

  /**
   * Renvoie la place selectionne si elle existe
   * @returns {any}
   */
  getSelectedPlace = (): VisioPlace | null => {
    if (this.selectedPlaceId !== null) {
      return this.getPlaceInfo(this.selectedPlaceId)
    } else {
      return null
    }
  }

  /**
   * Ajoute une couleur donnee à une salle donnee par son id
   * @param placeToColor
   */
  setPlaceColor = (placeToColor: { id: string; color: string }): any => {
    const tmpPlace = this.mapviewer.getPlace(placeToColor.id)
    if (tmpPlace.setColor) {
      tmpPlace.setColor(placeToColor.color)
    }
  }

  /**
   * Ajoute des couleurs a un tableau d'id et de couleur
   * @param placesToColor
   */
  colorPlaces = (placesToColor: { id: string; color: string }[]): void => {
    placesToColor.map((item) => this.setPlaceColor(item))
  }

  /**
   * Supprime la couleur d'une place donnee
   * @param {string} id
   */
  removePlaceColor = (id: string): void => {
    const tmpPlace = this.mapviewer.getPlace(id)
    if (tmpPlace.resetColor) {
      tmpPlace.resetColor()
    }
  }

  /**
   * Ajoute une couleur a une place donnee et la sauvegarde l'id de la place
   * @param placeToColor
   */
  addSelectedPlaceColor = (placeToColor: {
    id: string
    color: string
  }): void => {
    this.setPlaceColor(placeToColor)
    this.selectedPlaceId = placeToColor.id
  }

  /**
   * Supprime la couleur de la place sauvegardee et la supprime
   */
  removeSelectedPlaceColor = (): void => {
    if (this.selectedPlaceId != null) {
      this.removePlaceColor(this.selectedPlaceId)
      this.selectedPlaceId = null
    }
  }

  /**
   * Supprime l'ancienne place selectionnee et ajoute la nouvelle place selectionne
   * @param placeToColor
   */
  addSelectedPlaceColorAndRemovePrevious = (placeToColor: {
    id: string
    color: string
  }): void => {
    this.removeSelectedPlaceColor()
    this.addSelectedPlaceColor(placeToColor)
  }

  /**
   * SetPlaceName
   * @param id
   * @param labelOrOption
   */
  setPlaceName = (
    id: string,
    labelOrOption: string | LabelOrOptionType
  ): void => {
    this.mapviewer.setPlaceName(id, labelOrOption)
  }

  /**
   * Ne pas utiliser sauf si la fonction n'existe pas dans la lib
   */
  getMapviewer = (): any => {
    return this.mapviewer
  }

  /**
   * Ne pas utiliser sauf si la fonction n'existe pas dans la lib
   */
  getRoute = (): any => {
    return this.myRoute
  }

  /**
   * Ne pas utiliser sauf si la fonction n'existe pas dans la lib
   */
  getNavigation = (): any => {
    return this.myRoute.navigation
  }

  /**
   * Stop visioglobe
   */
  stop = (): any => {
    this.mapviewer.stop()
  }

  /**
   *
   * ------------------------------------------------------------- Internal function -------------------------------------------------------------------------------
   *
   */

  /**
   * Permet de mettre la vue multibuilding au cas ou on aurait une carte avec une seul batiment
   */
  setMultiBuilding = (): void => {
    this.mapviewer.setupMultiBuildingView(this.multiBuildingParameter)
    this.mapviewer.cameraDrivenExplorer.setEnabled(true)
    this.mapviewer.multiBuildingView.goTo({
      mode: "global",
      animationDuration: 0,
    })
    if (this.fctAfterMultiInit) {
      this.fctAfterMultiInit(true)
    }
  }

  /**
   * Trigger quand l'initialisation est complete
   */
  onInitializeCompleted = (): void => {
    this.mapviewer.start(this.mapParam)
    this.setState({ ...this.state, mapInit: true })
    if (this.fctAfterInit) {
      this.fctAfterInit(true)
    }
    if (this.props.multiParam !== undefined) {
      this.setMultiBuilding()
    }
    if (
      this.props.shouldUseExtraDataHeading &&
      this.mapviewer.getExtraData &&
      this.mapviewer.getExtraData() &&
      this.mapviewer.getExtraData().app_params &&
      this.mapviewer.getExtraData().app_params._extra &&
      this.mapviewer.getExtraData().app_params._extra.heading
    ) {
      this.mapviewer.camera.heading = this.mapviewer.getExtraData().app_params._extra.heading
    }
  }

  /**
   * Trigger initialisation de la carte
   */
  onLoadCompleted = (): void => {
    console.info("Visioglobe LOAD Completed")
  }
  onLoadFailed = (): void => {
    console.error("Visioglobe LOAD Failed")
  }
  onInitFailed = (): void => {
    console.error("Visioglobe INIT Failed")
  }

  /**
   * On initialise la carte
   */
  initVisioglobe = (
    onObjectMouseUp: ((e: any, el: HTMLElement) => void) | null,
    fctAfterInit: (value: boolean) => void,
    fctAfterMutliInit?: (value: boolean) => void
  ): void => {
    this.fctAfterInit = fctAfterInit
    this.fctAfterMultiInit = fctAfterMutliInit ? fctAfterMutliInit : null

    console.info("Visioglobe INITIALISATION")
    if (this.onlyOnce) {
      return
    } else {
      this.onlyOnce = true
      if (this.props.multiParam) {
        this.multiBuildingParameter = {
          ...this.multiBuildingParameter,
          container: this.myRef.current,
        }
      }

      this.mapviewer.on("initializeCompleted", this.onInitializeCompleted)
      this.mapviewer.on("loadCompleted", this.onLoadCompleted)
      this.mapviewer.on("initializedFailed", this.onLoadFailed)
      this.mapviewer.on("loadFailed", this.onInitFailed)

      this.mapviewer.initialize(this.myRef.current, {
        ...this.mapParam,
        onObjectMouseUp: onObjectMouseUp,
      })
    }
  }

  constructor(props: VisiomapProps) {
    super(props)
    this.state = { mapInit: false, itemClicked: null }
    this.myRef = React.createRef<HTMLDivElement>()
    this.myRoute = null
    this.mapParam = this.props.mapParam
    if (this.props.multiParam) {
      this.multiBuildingParameter = this.props.multiParam
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  render() {
    return (
      <>
        <div
          style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0 }}
        >
          <div
            key="map"
            ref={this.myRef}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              overflow: "hidden",
            }}
          />
          {this.state.itemClicked && this.props.placeInfoComponent && (
            <div className="tmp" id="tmp">
              <div className="center-child">
                <this.props.placeInfoComponent
                  place={this.mapviewer.getPlace(this.state.itemClicked.vg.id)}
                />
              </div>
            </div>
          )}
        </div>
        {!this.state.mapInit ? (
          <div
            style={{
              position: "absolute",
              top: "30%",
              right: 0,
              left: 0,
              color: "white",
            }}
          >
            <div className="loader" />
            <div className="loading-text">Loading Map</div>
          </div>
        ) : null}
      </>
    )
  }
}

export default VisioMapView
const VisioMapViewAny = VisioMapView as any

export const createView = (option: VisiomapOptions): any =>
  React.forwardRef<VisioMapViewRef, VisiomapProps>((props, ref) => (
    <VisioMapViewAny ref={ref} {...option} {...props} />
  ))
